@import '../../styles/variables';


.select-field__control {
  cursor: pointer !important;
}

.select-field__menu, .modal-dialog {
  .select-field__option {
    cursor: pointer;
  }
}

.selectInputContainer {
  width: 12.5rem;
  label {
    color: $field-label-color;
    padding-left: 1px;
  }
  .error {
    padding-left: 1px;
    padding-top: 1px;
    font-size: 0.85rem;
    min-height: 1.5rem;
    color: $field-error-color;
  }
  .infoIcon {
    width: 0.875rem;
    height: 0.875rem;
    margin-left: 5px;
  }
}


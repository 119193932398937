@import '../../styles/variables';

$user-logo-background: var(--burnt-orange);
$user-options-hover-background-color: var(--medium-blue-3);
$dropdown-item-hover-background: var(--medium-blue-3);

.appNavbar {
  height: $app-navbar-height;
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  .userOptions {
    border-radius: 0.5rem;
    &:hover {
      background-color: $user-options-hover-background-color;
    }
    .userLogo {
      background: $user-logo-background;
      border-radius: 50%;
      width: 2.25rem;
      height: 2.25rem;
      color: $white;
    }
  }
  .navbarDropdown {
    .navbarDropdownItem {
      padding: 0.3rem 1rem!important;
      &:hover {
        .iconWrapper {
          img {
            filter: none;
          }
        }
      }
      .iconWrapper {
        width: 2rem;
        height: 2rem;
        img {
          filter: brightness(0.3);
        }
      }
    }
  }
}

.brand img {
  margin-right: 1em;
}

@import '../../styles/variables';

$placeholder-color: rgba(var(--black-rgb), 0.4);
$searchbar-color: var(--black-30);

.searchbarContainer {
  position: relative;
  width: 12.5rem;
  .searchbar {
    padding: 0.5625rem 1.75rem;
    border: none;
    border-bottom: 1px solid $searchbar-color;
    background: transparent;
    outline: none;
    width: 100%;
    font-size: 0.875rem;
    &:focus {
      border-color: $field-focus-border-color;
      & + img {
        filter: none;
      }
    }
    &::-webkit-input-placeholder {
      /* Edge */
      color: $placeholder-color;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $placeholder-color;
    }

    &::placeholder {
      color: $placeholder-color;
    }
  }
  .searchbarOutlined {
    background: $white;
    border: .5px solid $searchbar-color;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: .875rem;
    outline: none;
    padding: .5625rem 1.75rem;
    width: 100%;
    &:focus {
      border-color: $field-focus-border-color;
      box-shadow: inset 0 4px 8px $field-focus-box-shadow-color;
      & + img {
        filter: none;
      }
    }
  }
  img {
    filter: brightness(1.99);
    height: .75rem;
    left: .5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: .75rem;
  }
}

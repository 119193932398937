@import '../../styles/variables';

.customTable {
  overflow-x: auto;
  width: 100%;
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    th {
      background: $table-head-background;
      box-shadow: inset 0 -0.5px 0 $field-placeholder-color;
      color: $table-head-color;
      font-size: 0.75rem;
      height: 2rem;
      padding: 0.5rem;
      text-align: left;
      text-transform: uppercase;
    }
    tr {
      &:hover {
        background-color: $primary-background;
      }
      > {
        :first-child {
          padding-left: 1.25rem;
        }
        :last-child {
          padding-right: 1rem;
        }
      }
    }
    tbody {
      tr {
        height: 3.25rem;
      }
      :last-child td {
        box-shadow: none !important;
      }
    }
    td {
      box-shadow: inset 0 -0.5px 0 $field-placeholder-color;
      color: var(--primary-title-text-color);
      padding: 0.5rem;
    }
  }
  .iconHover {
    align-items: center;
    background-color: $white;
    border: 1px solid var(--primary-error-color);
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
  }
  .deleteIcon {
    height: 1.6rem;
    min-width: 1.5rem;
    width: 1.6rem;
    &:hover,
    &:active {
      @extend .iconHover;
    }
    &:active {
      background-color: var(--primary-error-color);
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .editIcon {
    height: 1.5rem;
    margin-right: 0.5rem;
    min-width: 1.5rem;
    width: 1.5rem;
    &:hover,
    &:active {
      @extend .iconHover;
      border: 1px solid var(--secondary-btn-border-color);
    }
    &:active {
      background-color: var(--secondary-btn-border-color);
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .viewIcon {
    height: 1.5rem;
    margin-right: 0.5rem;
    min-width: 1.5rem;
    width: 1.5rem;
    &:hover,
    &:active {
      @extend .iconHover;
      border: 1px solid var(--secondary-btn-border-color);
    }
    &:active {
      background-color: var(--secondary-btn-border-color);
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .downloadIcon {
    height: 1.5rem;
    margin-right: 0.5rem;
    min-width: 1.5rem;
    width: 1.5rem;
    &:hover,
    &:active {
      @extend .iconHover;
      border: 1px solid var(--secondary-btn-border-color);
    }
    &:active {
      background-color: var(--secondary-btn-border-color);
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  .customNoHoverIcon {
    height: 1.5rem;
    margin-right: 0.5rem;
    min-width: 1.5rem;
    width: 1.5rem;
  }
  .customIcon {
    height: 1.5rem;
    margin-right: 0.5rem;
    min-width: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    &:hover,
    &:active {
      height: 1.6rem !important;
      width: 1.6rem !important;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid var(--secondary-btn-border-color);
    }
    &:active {
      background-color: var(--secondary-btn-border-color);
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  .customSuccessIcon{
    &:hover,
    &:active {
    border: 1px solid var(--green) !important;
    }
    &:active {
      background-color: var(--green);
      img {
        filter: brightness(0) invert(1);
      }
    }

  }
  
  .paginationWrapper {
    padding: 1.25rem;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  .pointer {
    cursor: pointer;
    tr:hover td {
      color: var(--medium-blue) !important;
    }
  }
}

.btnImg {
  filter: brightness(0) invert(1);
  margin-right: 5px;
}

.iconButton {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: .4rem .75rem !important;
}
@import '../../styles/variables';

.cards {
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
  border-radius: 16px;
}

.width-22 {
  width: 22% !important;
} 

.card-text {
  font-style: normal;
  font-weight: 600;
  font-size: 1.1vw;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #2514BE;  
}
